import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import notificationService from '../services/notificationSV';
import moment from 'moment-timezone';

export const fetchUnreadNotifications = createAsyncThunk(
  'notifications/fetchUnread',
  async () => {
    const unreadNotifications = await notificationService.fetchUnreadNotifications();
    return unreadNotifications;
  }
);

export const fetchAllNotifications = createAsyncThunk(
  'notifications/fetchAll',
  async () => {
    const allNotifications = await notificationService.fetchAllNotifications();
    return allNotifications;
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    allItems: [],
    unreadItems: [],
    tempBookingRequests: [],
    status: 'idle',
    error: null
  },
  reducers: {
    setAllNotifications: (state, action) => {
      state.allItems = action.payload;
      localStorage.setItem('allNotifications', JSON.stringify(action.payload));
    },
    setUnreadNotifications: (state, action) => {
      state.unreadItems = action.payload;
      state.status = 'succeeded';
      localStorage.setItem('unreadNotifications', JSON.stringify(action.payload));
    },
    
    addNotification: (state, action) => {
      // 只添加非临时预订请求的通知到未读列表
      if (action.payload.type !== 'TEMP_BOOKING_REQUEST') {
        state.unreadItems.unshift(action.payload);
      }
    },

    removeNotification: (state, action) => {
      // 如果 action.payload 是函数，则用作过滤条件
      if (typeof action.payload === 'function') {
        // 找到要删除的通知的 ID 列表
        const notificationsToRemove = state.unreadItems.filter(action.payload);
        
        // 从 unreadItems 中移除匹配的通知
        state.unreadItems = state.unreadItems.filter(item => !action.payload(item));
        
        // 更新 allItems 中对应通知的状态
        notificationsToRemove.forEach(notification => {
          const index = state.allItems.findIndex(n => n.id === notification.id);
          if (index !== -1) {
            state.allItems[index].read = true;
          }
        });
      } else {
        // 原有的按 ID 删除逻辑
        state.unreadItems = state.unreadItems.filter(n => n.id !== action.payload);
        // 更新 allItems 中的通知状态
        const index = state.allItems.findIndex(n => n.id === action.payload);
        if (index !== -1) {
          state.allItems[index].read = true;
        }
      }
      
      // 更新本地存储
      localStorage.setItem('unreadNotifications', JSON.stringify(state.unreadItems));
      localStorage.setItem('allNotifications', JSON.stringify(state.allItems));
    },

    clearAllNotifications: (state) => {
      state.unreadItems = [];
      state.allItems = state.allItems.map(n => ({ ...n, read: true }));
      localStorage.removeItem('unreadNotifications');
      localStorage.setItem('allNotifications', JSON.stringify(state.allItems));
    },
    setError: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    updateAllNotifications: (state, action) => {
      state.allItems = action.payload;
      localStorage.setItem('allNotifications', JSON.stringify(action.payload));
    },

    // 添加新的 reducers 处理临时预订请求
    setTempBookingRequests: (state, action) => {
      state.tempBookingRequests = action.payload;
      localStorage.setItem('tempBookingRequests', JSON.stringify(action.payload));
    },
    
    addTempBookingRequest: (state, action) => {
      const newRequest = action.payload;
      console.log('正在添加临时预订请求:', newRequest);
      
      const exists = state.tempBookingRequests.some(
        req => 
          req.context.studentId === newRequest.context.studentId &&
          req.context.courseDate === newRequest.context.courseDate &&
          req.context.startTime === newRequest.context.startTime
      );
      
      if (!exists) {
        const courseDateTime = moment.tz(
          `${newRequest.context.courseDate} ${newRequest.context.startTime}`,
          'YYYY-MM-DD HH:mm:ss',
          'Asia/Shanghai'
        );
        
        if (courseDateTime.isAfter(moment().tz('Asia/Shanghai'))) {
          state.tempBookingRequests.unshift(newRequest);
          localStorage.setItem('tempBookingRequests', JSON.stringify(state.tempBookingRequests));
          console.log('临时预订请求添加成功，当前列表:', state.tempBookingRequests);
        }
      }
    },
    
    setTempBookingRequests: (state, action) => {
      console.log('设置临时预订请求列表:', action.payload);
      state.tempBookingRequests = action.payload;
      localStorage.setItem('tempBookingRequests', JSON.stringify(action.payload));
    },

    cleanExpiredRequests: (state) => {
      const now = moment().tz('Asia/Shanghai');
      const beforeClean = state.tempBookingRequests.length;
      
      state.tempBookingRequests = state.tempBookingRequests.filter(request => {
        const courseDateTime = moment.tz(
          `${request.context.courseDate} ${request.context.startTime}`,
          'YYYY-MM-DD HH:mm:ss',
          'Asia/Shanghai'
        );
        return courseDateTime.isAfter(now);
      });
      
      const afterClean = state.tempBookingRequests.length;
      if (beforeClean !== afterClean) {
        console.log(`清理了 ${beforeClean - afterClean} 个过期请求`);
        localStorage.setItem('tempBookingRequests', JSON.stringify(state.tempBookingRequests));
      }
    },
        
    removeTempBookingByMatch: (state, action) => {
      const { studentId, courseDate, startTime } = action.payload;
      console.log('正在删除临时预订请求:', { studentId, courseDate, startTime });
      
      // 更精确的筛选条件
      state.tempBookingRequests = state.tempBookingRequests.filter(request => {
        // 只处理 TEMP_BOOKING_REQUEST 类型的请求
        if (request.type !== 'TEMP_BOOKING_REQUEST') {
          return true; // 保留非临时预订请求的通知
        }
        
        // 匹配具体的预订信息
        const isMatch = (
          request.context.studentId === studentId &&
          request.context.courseDate === courseDate &&
          request.context.startTime === startTime &&
          request.type === 'TEMP_BOOKING_REQUEST'  // 明确类型匹配
        );
        
        if (isMatch) {
          console.log('找到匹配的临时预订请求，将被删除:', request);
        }
        
        return !isMatch; // 返回 true 表示保留，false 表示删除
      });
    
      // 记录删除后的状态
      console.log('删除后的临时预订请求列表:', state.tempBookingRequests);
      
      // 更新本地存储
      localStorage.setItem('tempBookingRequests', JSON.stringify(state.tempBookingRequests));
    },

    updateNotificationReadStatus: (state, action) => {
      const notificationId = action.payload;
      
      // 更新通知的已读状态
      const allIndex = state.allItems.findIndex(n => n.id === notificationId);
      if (allIndex !== -1) {
        state.allItems[allIndex].read = true;
      }
      
      // 从未读列表中移除
      state.unreadItems = state.unreadItems.filter(n => n.id !== notificationId);
      
      // 注意：不从 tempBookingRequests 中移除未处理的请求
      
      localStorage.setItem('allNotifications', JSON.stringify(state.allItems));
      localStorage.setItem('unreadNotifications', JSON.stringify(state.unreadItems));
    },

    initializeFromStorage: (state) => {
      const storedTempBookings = localStorage.getItem('tempBookingRequests');
      if (storedTempBookings) {
        state.tempBookingRequests = JSON.parse(storedTempBookings);
      }
      
      const storedAllNotifications = localStorage.getItem('allNotifications');
      if (storedAllNotifications) {
        state.allItems = JSON.parse(storedAllNotifications);
      }
      
      const storedUnreadNotifications = localStorage.getItem('unreadNotifications');
      if (storedUnreadNotifications) {
        state.unreadItems = JSON.parse(storedUnreadNotifications);
      }
    }
    },
  
  extraReducers: (builder) => {
    builder
    .addCase(fetchUnreadNotifications.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchUnreadNotifications.fulfilled, (state, action) => {
      state.status = 'succeeded';
      // 过滤掉 TEMP_BOOKING_REQUEST 类型的通知
      const filteredUnread = action.payload.filter(notification => notification.type !== 'TEMP_BOOKING_REQUEST');
      state.unreadItems = filteredUnread;
      localStorage.setItem('unreadNotifications', JSON.stringify(filteredUnread));
    })
    .addCase(fetchUnreadNotifications.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(fetchAllNotifications.fulfilled, (state, action) => {
      state.allItems = action.payload;
      localStorage.setItem('allNotifications', JSON.stringify(action.payload));
    });
  }
});

export const { 
  setAllNotifications,
  setUnreadNotifications, 
  addNotification, 
  removeNotification, 
  clearAllNotifications, 
  setError,
  updateAllNotifications,
  addTempBookingRequest,
  setTempBookingRequests,
  removeTempBookingByMatch,
  updateNotificationReadStatus,
  cleanExpiredRequests,
  initializeFromStorage
} = notificationsSlice.actions;

export default notificationsSlice.reducer;